import React from "react";
import Headerbar from "./components/header";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DOMPurify from "dompurify";

function RelatedArticlesInfoPage() {
  const { index } = useParams();
  const data = useSelector((state) => state.data);
  const topicIndex = useSelector((state) => state.selectedOptions.topicIndex);
  const issueIndex = useSelector((state) => state.selectedOptions.issueIndex);
  const kbArticle = data[topicIndex].issues[issueIndex].kb[index];
  const sanitizedHTML = DOMPurify.sanitize(kbArticle.content);

  return (
    <div>
      <Headerbar title={"Article"} />
      <div
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "55px",
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizedHTML,
        }}
      ></div>
    </div>
  );
}

export default RelatedArticlesInfoPage;
