import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import Headerbar from "./components/header";
import LoadingOverlay from "./components/loading_overlay";
import * as workpalJS from "@workpal-build/workpaljs";
import config from "../config";
import {
  MyDetails,
  TopicsOptions,
  IssuesOptions,
  DevicesOptions,
  CustomFormControl,
  Checkboxes,
  ErrorDialog,
} from "./incident_form_components";
import ReactGA from "react-ga4";

const styles = {
  descCount: {
    textAlign: "right",
    fontSize: "12px",
    color: "gray",
    marginTop: "-5px",
  },
  pr20: {
    paddingRight: "20px",
  },
  borderColor: {
    borderColor: "rgba(0, 0, 0, 0.23) !important",
  },
  uploadLabel: {
    display: "flex",
    padding: " 12px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
  },
  uploadBtn: {
    marginRight: "auto",
    marginLeft: "auto",
    fontSize: "16px",
    fontWeight: "700",
  },
};

const RequestIncidentForm = () => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploaderrorOpen, setuploaderrorOpen] = useState(false);
  const [uploaderrorMessage, setUploaderrorMessage] = useState("");
  const [errorFiles, setErrorFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const { userapi, user, data, selectedOptions, loading } = useSelector(
    (state) => state
  );
  const location = useLocation();
  const { source } = location.state || {};

  const devices = userapi.cmdbArray;
  const userCurrentDevice = user.device;
  const topics = data;
  const loadedTopic = topics[selectedOptions.topicIndex];
  const loadedIssue = loadedTopic.issues[selectedOptions.issueIndex];
  const jwt = user.jwt;

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [formData, setFormData] = useState({
    userId: userapi.userSysId,
    name: userapi.name,
    email: userapi.email.toUpperCase(),
    agency: userapi.agency,
    selectedTopic: loadedTopic?.topic || null,
    selectedIssueId: loadedIssue?.id || null,
    selectedIssue: loadedIssue?.name || null,
    selectedDeviceId: null, // device_sys_id, NIL, not_applicable
    selectedDevice: null, // model device id (from API)
    deviceInfo: null, // if user's current device, device full json info; if from API, device id
    description: "",
    charCount: 0,
    contactType: "email",
    contactValue: userapi.email.toUpperCase(),
    filename: [],
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = () => resolve(reader.result);
      reader.onload = () => {
        const base64Data = reader.result;
        // remove the prefix "data:image/jpeg;base64,"
        const base64String = base64Data.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadDoc = async (file) => {
    try {
      const base64 = await convertToBase64(file);
      let requestData = new FormData();
      requestData.append("filename", file.name);
      requestData.append("file", JSON.stringify(base64));

      const response = await axios.post(
        `${config.API_UPLOAD_URL}`,
        requestData,
        {
          headers: {
            Authorization: "Bearer " + jwt,
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data.data;
      if (!result.error) {
        setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
        formData.filename.push(result.sys_filename);
        return result.sys_filename;
      }
    } catch (error) {
      console.error("Error uploading file:", file.name, error);
    }
  };

  const handleFileSelect = async (e) => {
    setLoadingMessage(
      <div style={{ marginBottom: "10px" }}>"Uploading attachment(s)..."</div>
    );
    setIsLoading(true);
    setuploaderrorOpen(false);
    const files = Array.from(e.target.files);

    if (uploadedFiles.length + files.length > 2) {
      let errorMsg = "Maximum 2 attachments. ";
      if (uploadedFiles.length === 0 && files.length > 2) {
        errorMsg += "Please select up to 2 files to upload.";
      } else if (uploadedFiles.length === 1 && files.length > 1) {
        errorMsg += "You can only select 1 more file to upload.";
      }
      setUploaderrorMessage(errorMsg);
      setuploaderrorOpen(true);
      e.target.value = null;
      setIsLoading(false);
      return;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB

    let failedFiles = [];

    // separate valid and invalid files based on size and type
    const [validFiles, invalidFiles] = files.reduce(
      (acc, file) => {
        const fileSizeValid = file.size <= maxSize;
        // full list
        // const fileExtensionValid =
        //   /\.(csv|txt|rtf|doc|docx|xls|xlsx|pdf|zip|gif|msg|rar|cer|png|jpe?g|jar|ico|properties|pptx|p7b|svg|xml|ps1)$/i.test(
        //     file.name
        //   );
        const fileExtensionValid = /\.(png|jpe?g)$/i.test(file.name);
        let msg = [];
        if (fileSizeValid && fileExtensionValid) {
          acc[0].push(file); // valid file
        } else {
          acc[1].push(file);
          if (!fileSizeValid) {
            msg.push("Size exceeds 5mb limit");
          }
          if (!fileExtensionValid) {
            msg.push("File type not supported");
          }

          failedFiles.push({ name: file.name, message: msg });
        }
        return acc;
      },
      [[], []]
    );
    setErrorFiles(failedFiles);

    if (invalidFiles.length > 0) {
      setUploaderrorMessage(
        invalidFiles.length + " file(s) could not be uploaded."
      );
      setuploaderrorOpen(true);
    }

    try {
      await Promise.all(validFiles.map((file) => uploadDoc(file)));
    } catch (error) {
      setUploaderrorMessage("Failed to upload one or more files.");
      setuploaderrorOpen(true);
    }

    setIsLoading(false);
  };

  const handleRemoveFile = (index) => {
    setLoadingMessage(
      <div style={{ marginBottom: "10px" }}>"Removing file..."</div>
    );
    setIsLoading(true);
    setuploaderrorOpen(false);
    setUploadedFiles((prevFiles) => [
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);

    setFormData((prevData) => {
      const updatedFiles = [...prevData.filename];
      updatedFiles.splice(index, 1);

      return {
        ...prevData,
        filename: updatedFiles,
      };
    });
    setIsLoading(false);
  };

  const [isChecked2, setIsChecked2] = useState(false);

  const [isTopicsOpen, setIsTopicsOpen] = useState(false);
  const [isIssuesOpen, setIsIssuesOpen] = useState(false);
  const [isDevicesOpen, setIsDevicesOpen] = useState(false);

  const [noback, setNoback] = useState(false);
  const [currentIssues, setCurrentIssues] = useState(loadedTopic.issues);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [serviceType, setServiceType] = useState("");

  const [topicError, setTopicError] = useState("");
  const [issueError, setIssueError] = useState("");
  const [deviceError, setDeviceError] = useState("");
  const [descError, setDescError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [tncError, setTncError] = useState("");

  let title = "Report IT issue";
  const [pagetitle, setPageTitle] = useState(title);

  const handleCloseOption = () => {
    setIsTopicsOpen(false);
    setIsIssuesOpen(false);
    setIsDevicesOpen(false);
    setPageTitle(title);
    setNoback(false);
  };

  const handleTopicSelect = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedTopic: option,
      charCount: prevData.description.length,
      selectedIssue: null,
      selectedIssueId: null,
    }));

    updateDeviceInfo(null, null);
    setIsTopicsOpen(false);
    setPageTitle(title);
    setNoback(false);
    setTopicError("");
    setIssueError("");
    setDeviceError("");

    setCurrentIssues(
      topics.find((item) => item.topic === option)?.issues || []
    );
    setServiceType(null);
  };

  const handleIssueSelect = (id, name) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedIssueId: id,
      selectedIssue: name,
    }));
    setPageTitle(title);
    setIsIssuesOpen(false);
    setNoback(false);
    setIssueError("");
  };

  useEffect(() => {
    setServiceType(
      currentIssues.find((item) => item.id === formData.selectedIssueId)
        ?.service_type || null
    );
  }, [formData.selectedIssueId]);

  const updateDeviceInfo = (id, name) => {
    // source === "msAuthenticator" ? userCurrentDevice.model : null,
    setFormData((prevData) => ({
      ...prevData,
      selectedDeviceId: id,
      selectedDevice: id === "NIL" ? userCurrentDevice.model : name,
      deviceInfo: id === "NIL" ? JSON.stringify(userCurrentDevice) : id,
    }));
    setDeviceError("");
  };

  useEffect(() => {
    if (userapi.totalDevice === 0 && serviceType === "Web") {
      setFilteredDevices([
        {
          device_sys_id: "not_applicable",
          name: "Not Applicable",
        },
      ]);
      updateDeviceInfo("not_applicable", "Not Applicable");
    } else if (serviceType === "Mobile" || source === "msAuthenticator") {
      // user's current device
      setFilteredDevices([
        {
          device_sys_id: "NIL",
          name: userCurrentDevice.model,
        },
      ]);
      updateDeviceInfo("NIL", JSON.stringify(userCurrentDevice)); // full device info
    } else if (serviceType === "Web") {
      setFilteredDevices(devices); // get from API

      if (userapi.totalDevice === 1) {
        updateDeviceInfo(devices[0].device_sys_id, devices[0].name);
      }
    } else {
      updateDeviceInfo(null, null);
      // all devices, current + API
      setFilteredDevices([
        ...devices,
        {
          device_sys_id: "NIL",
          name: userCurrentDevice.model,
        },
      ]);
    }
  }, [serviceType]);

  const handleDeviceSelect = (id, name) => {
    updateDeviceInfo(id, name);

    setPageTitle(title);
    setIsDevicesOpen(false);
    setNoback(false);

    setDeviceError("");
  };

  const handleFieldChange = (field, value) => {
    if (field === "description") {
      // let sanitizedValue = value.replace(/[^a-zA-Z0-9\s.,!?]/g, "");
      if (value.length > 200) {
        value = value.substring(0, 200);
        setDescError("Maximum 200 characters allowed.");
      } else {
        setDescError("");
      }
      setFormData((prevData) => ({
        ...prevData,
        [field]: value, //sanitizedValue,
        charCount: value.length, //sanitizedValue.length,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [field]: value }));
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    // Add a space between the 4th and 5th characters
    const formattedValue = value.replace(/(\d{4})(\d)/, "$1 $2");
    const valueWithoutSpace = value.replace(/\s/g, "");

    if (/^[689]\d{0,7}$/.test(valueWithoutSpace) || valueWithoutSpace === "") {
      handleFieldChange("contactValue", formattedValue);
    }
    setPhoneNumberError("");
  };

  const navigate = useNavigate();

  const handleContactType = (checked) => {
    const contactType = checked ? "phone" : "email";
    handleFieldChange("contactType", contactType);
    handleFieldChange(
      "contactValue",
      contactType === "email" ? formData.email : ""
    );
  };

  useEffect(() => {
    if (isChecked2) {
      setTncError("");
    }
  }, [isChecked2]);

  const validateFormFields = () => {
    const isFormValid =
      formData.selectedIssueId &&
      formData.selectedTopic &&
      formData.selectedDeviceId &&
      formData.description &&
      formData.contactValue &&
      (formData.contactType === "email" ||
        (formData.contactType === "phone" && isChecked2));

    if (isFormValid) {
      setTopicError("");
      setIssueError("");
      setDeviceError("");
      setDescError("");
      return isFormValid;
    } else {
      const error = "This field is required.";
      if (!formData.selectedTopic) {
        setTopicError(error);
      }
      if (!formData.selectedIssueId) {
        setIssueError(error);
      }
      if (!formData.selectedDeviceId) {
        setDeviceError(error);
      }
      if (!formData.description) {
        setDescError(error);
      }
      if (formData.contactType === "phone" && !formData.contactValue) {
        setPhoneNumberError(error);
      }
      if (formData.contactType === "phone" && !isChecked2) {
        setTncError("Please agree to the terms and conditions");
      }

      throw new Error("Missing form fields");
    }
  };

  const isFormValid =
    formData.selectedIssueId &&
    formData.selectedTopic &&
    formData.selectedDeviceId &&
    formData.description &&
    formData.contactValue &&
    (formData.contactType === "email" ||
      (formData.contactType === "phone" && isChecked2));

  const contactValueWithoutSpace = formData.contactValue.replace(/\s/g, "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateFormFields();
    validatePhoneNumber();

    setLoadingMessage(
      <div style={{ marginBottom: "10px" }}>"Reporting issue..."</div>
    );
    setIsLoading(true);
    const topicIndex = data.findIndex(
      (topic) => topic.topic === formData.selectedTopic
    );

    await workpalJS.refreshAccessToken();

    try {
      const requestData = buildRequestData();

      const response = await axios.post(
        `${config.API_BASE_URL}createincident`,
        requestData,
        {
          headers: {
            Authorization: "Bearer " + jwt,
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data.data;

      if (!result.error) {
        //Google Analytics to track user submitted form successfully
        ReactGA.gtag("event", "Report Issue", {
          result: "Success",
          issueName: formData.selectedIssue,
          issueId: formData.selectedIssueId,
          topicName: formData.selectedTopic,
          topicId: data[topicIndex].id,
          sessionIdCustom: userapi.sessionID,
          userId: userapi.userSysId,
          agency: userapi.agency,
          channel: "Mobile",
          timestamp: Date(),
        });
        // ReactGA.event({
        //   category: "Successful",
        //   action: `User submitted form`,
        //   label: `Timestamp ${Date()}`,
        // });

        navigate("/confirmation_page", {
          state: {
            incidentNumber: result.case_number,
            emailAddress: formData.email,
          },
        });
      } else {
        handleSubmissionError(response, topicIndex);
      }
    } catch (error) {
      handleSubmissionError(error, topicIndex);
    } finally {
      setIsLoading(false);
    }
  };

  const validatePhoneNumber = () => {
    if (
      formData.contactType === "phone" &&
      !/^\d{8}$/.test(contactValueWithoutSpace)
    ) {
      setPhoneNumberError("Invalid phone number format");
      throw new Error("Invalid phone number format");
    } else {
      setPhoneNumberError("");
    }
  };

  const buildRequestData = () => ({
    shortDescription: formData.description,
    userSysId: userapi.userSysId,
    issueSysId: formData.selectedIssueId,
    deviceInfo: formData.deviceInfo,
    deviceId: formData.selectedDeviceId,
    contactType: formData.contactType,
    contactValue: contactValueWithoutSpace,
    agency: userapi.agency,
    filename: formData.filename,
  });

  const handleSubmissionError = (error, topicIndex) => {
    //Google Analytics to track user submitted form unsuccessfully
    ReactGA.gtag("event", "Report Issue", {
      result: "Failed",
      issueName: formData.selectedIssue,
      issueId: formData.selectedIssueId,
      topicName: formData.selectedTopic,
      topicId: data[topicIndex].id,
      sessionIdCustom: userapi.sessionID,
      userId: userapi.userSysId,
      agency: userapi.agency,
      channel: "Mobile",
      timestamp: Date(),
    });
    setIsLoading(false);
    setErrorMessage(
      "An error occurred while submitting, please try again. \n"
      // +
      // JSON.stringify(error)
    );
    setErrorDialogOpen(true);
  };

  const truncateFileName = (filename) => {
    const maxLength = 32;
    if (filename.length <= maxLength) {
      return filename;
    }
    const startLength = 22;
    const endLength = 7;
    return `${filename.substring(0, startLength)}...${filename.substring(
      filename.length - endLength
    )}`;
  };

  const handleDialogClose = () => {
    setErrorDialogOpen(false);
    setuploaderrorOpen(false);
  };

  const renderTopicOptions = () => {
    return (
      <TopicsOptions
        topics={topics}
        handleTopicSelect={handleTopicSelect}
        selected={formData.selectedTopic}
      />
    );
  };

  const renderIssueOptions = () => {
    return (
      <IssuesOptions
        issues={currentIssues}
        handleIssueSelect={handleIssueSelect}
        selected={formData.selectedIssue}
      />
    );
  };

  const renderDeviceOptions = () => {
    return (
      <DevicesOptions
        devices={filteredDevices}
        handleDeviceSelect={handleDeviceSelect}
        selected={formData.selectedDevice}
      />
    );
  };

  return (
    <>
      <Headerbar
        title={pagetitle}
        closeOption={noback ? handleCloseOption : null}
      />
      <div style={{ paddingTop: "55px" }}></div>

      {isTopicsOpen && renderTopicOptions()}
      {isIssuesOpen && renderIssueOptions()}
      {isDevicesOpen && renderDeviceOptions()}

      {!isTopicsOpen && !isIssuesOpen && !isDevicesOpen && (
        <Box
          component="form"
          sx={{ width: "100%" }}
          noValidate
          autoComplete="off"
        >
          <ErrorDialog
            open={errorDialogOpen}
            onClose={handleDialogClose}
            errorMessage={errorMessage}
          />

          <LoadingOverlay isOpen={isLoading} message={loadingMessage} />
          <MyDetails formData={formData} />

          <Divider />
          <Container className="mt-3 mb-3">
            <Typography variant="h6" gutterBottom>
              Issue Details
            </Typography>
            <CustomFormControl
              label="Topic"
              value={formData.selectedTopic}
              onClick={() => {
                setIsTopicsOpen(true);
                setPageTitle("Topics");
                setNoback(true);
              }}
              error={!!topicError}
              helperText={topicError}
            />
            <CustomFormControl
              label="Issue"
              value={formData.selectedIssue}
              disabled={!formData.selectedTopic}
              onClick={
                formData.selectedTopic
                  ? () => {
                      setIsIssuesOpen(true);
                      setPageTitle("Issues");
                      setNoback(true);
                    }
                  : null
              }
              error={!!issueError}
              helperText={issueError}
            />
            <CustomFormControl
              label="Device Experiencing the Issue"
              value={formData.selectedDevice}
              disabled={!formData.selectedIssue}
              onClick={
                formData.selectedIssue
                  ? () => {
                      setIsDevicesOpen(true);
                      setPageTitle("Devices");
                      setNoback(true);
                    }
                  : null
              }
              error={!!deviceError}
              helperText={deviceError}
            />
            <TextField
              fullWidth
              id="description"
              label="Description"
              multiline
              variant="standard"
              margin="normal"
              value={formData.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              InputLabelProps={{ focused: false }}
              InputProps={{
                maxLength: 200,
              }}
              maxLength={200}
              style={styles.borderColor}
              error={!!descError}
              helperText={descError}
            />
            <div style={styles.descCount}>
              {formData.charCount}/{200}
            </div>

            <div>
              <Typography variant="h7" gutterBottom>
                Attachments (Optional)
              </Typography>
              <div>
                <Typography variant="caption" gutterBottom>
                  File limit: maximum 2 files
                  <br />
                  File size limit: 5MB per file
                  <br />
                  Supported file types: PNG, JPG, JPEG.{" "}
                </Typography>

                {uploadedFiles.length > 0 && (
                  <div>
                    {uploadedFiles.map((filename, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            color: "#757575",
                            paddingTop: "10px",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "14px",
                            letterSpacing: "0.3px",
                            fontFamily: "Open Sans",
                          }}
                        >
                          <img
                            src={require("../img/attachment_icon.svg").default}
                            alt="File"
                            style={{
                              width: "19px",
                              margin: "auto",
                              marginRight: "8px",
                            }}
                          />
                          {truncateFileName(filename)}
                        </span>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                          }}
                        >
                          <CancelIcon
                            style={{
                              marginTop: "10px",
                              width: "19px",
                              color: "#AAAAAA",
                              right: "0",
                            }}
                          />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                )}

                <input
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                  id="fileInput"
                  accept=".png,.jpg,.jpeg"
                />
                <label
                  htmlFor={uploadedFiles.length >= 2 ? "" : "fileInput"}
                  style={styles.uploadLabel}
                >
                  <Button
                    variant="text"
                    component="span"
                    style={styles.uploadBtn}
                    disabled={uploadedFiles.length >= 2}
                  >
                    <img
                      src={
                        require(uploadedFiles.length >= 2
                          ? "../img/upload-grey.svg"
                          : "../img/upload_icon.svg").default
                      }
                      alt="Upload"
                      style={{
                        width: "26px",
                        display: "flex",
                        margin: "auto",
                        marginRight: "10px",
                      }}
                    />
                    Upload documents
                  </Button>
                </label>
              </div>
              {uploaderrorOpen && (
                <div className="upload_error">
                  <Typography style={{ color: "#C73333" }} paragraph>
                    <ErrorIcon style={{ width: "19px" }} /> {uploaderrorMessage}
                  </Typography>
                  {errorFiles && (
                    <div>
                      {errorFiles.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{ paddingLeft: "20px", color: "#C73333" }}
                            paragraph
                          >
                            {file.name}
                            {file.message.map((msg, index) => (
                              <Typography
                                variant="h6"
                                key={index}
                                component="li"
                                style={{
                                  paddingLeft: "10px",
                                  color: "#C73333",
                                }}
                              >
                                {msg}
                              </Typography>
                            ))}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Container>
          <Divider />

          <Container className="mt-3 mb-3">
            <Typography variant="h6" gutterBottom>
              Contact Method
            </Typography>

            <Typography style={styles.pr20}>
              By default, you will be contacted via this IT Support mini-app and
              your work email address.
            </Typography>

            <FormControlLabel
              control={<Checkbox />}
              className="mt-3"
              label="I prefer to be contacted via phone call (Optional)"
              checked={formData.contactType === "phone"}
              onChange={(e) => handleContactType(e.target.checked)}
            />

            {formData.contactType === "email" && (
              <TextField
                id="contact-email"
                label="Email address to contact"
                type="email"
                value={formData.email}
                required={formData.contactType === "email"}
                InputProps={{ readOnly: true, disableUnderline: true }}
                variant="standard"
                fullWidth
                margin="normal"
                style={{ display: "none" }}
              />
            )}
            {formData.contactType === "phone" && (
              <div>
                <TextField
                  id="contact-phone"
                  label="Contact Number"
                  type="tel"
                  value={
                    formData.contactType === "phone"
                      ? formData.contactValue
                      : ""
                  }
                  onChange={handlePhoneChange}
                  required={formData.contactType === "phone"}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+65</InputAdornment>
                    ),
                    inputProps: { maxLength: 13 },
                  }}
                  margin="normal"
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                />
                <Checkboxes
                  isChecked2={isChecked2}
                  setIsChecked2={setIsChecked2}
                  onClickLink={() => {
                    workpalJS.openExternalBrowser(
                      "https://www.tech.gov.sg/privacy/"
                    );
                  }}
                  error={!!tncError}
                  helperText={tncError}
                />
              </div>
            )}
          </Container>

          <Divider />
          <Container className="mb-3">
            <Button
              fullWidth
              variant="contained"
              disableElevation
              className="mt-3"
              onClick={handleSubmit}
              // disabled={!isFormValid || loading}
              disabled={loading}
            >
              Report
            </Button>
          </Container>
        </Box>
      )}
    </>
  );
};

export default RequestIncidentForm;
