import { Box } from "@mui/material";
import React, { useEffect } from "react";
import TopicListTile from "../landing_page/components/topic_list_tile";
import { useSelector } from "react-redux";

import Headerbar from "../components/header";

function AllTopicsPage() {
  const topics = useSelector((state) => state.data);

  const topicList = [];
  topics.map((eachTopic) =>
    topicList.push({
      id: eachTopic.id,
      topic: eachTopic.topic,
      icon: eachTopic.icon,
    })
  );

  const removeOthersFromList = topicList.filter((e) => e.topic != "Others");

  const sortedList = removeOthersFromList.sort((a, b) => {
    return a.topic.localeCompare(b.topic, undefined, { sensitivity: "base" });
  });

  const grouping = (arr) => {
    return arr.reduce((accVal, currVal) => {
      var key = currVal.topic.charAt(0);
      if (!accVal[key]) {
        accVal[key] = [];
      }
      accVal[key].push(currVal);
      return accVal;
    }, {});
  };
  const groupedTopics = grouping(sortedList);
  // alert(JSON.stringify(groupedTopics));

  const othersicon = topicList.filter((e) => e.topic == "Others")[0].icon;
  groupedTopics["Topic not listed?"] = [{ topic: "Others", icon: othersicon }];

  const styles = {
    page: {
      padding: "16px",
      display: "flex",
      alignItems: "center",
    },
    title: {
      background: "#F8F8F8",
      color: "#4D4D4D",
      fontSize: "16px",
      fontWeight: 700,
      padding: "12px",
      position: "sticky",
      top: 55,
      zIndex: 1,
    },

    h1FontStyle: {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "17px",
      //   lineHeight: "28px",
    },
  };
  return (
    <Box sx={{}}>
      <Headerbar title={"All topics"} />
      <Box sx={{ height: 55 }}></Box>
      <Box>
        {Object.keys(groupedTopics).map((key, i) => {
          return (
            <Box key={i}>
              <Box sx={styles.title}>{key.toUpperCase()}</Box>
              {groupedTopics[key].map((topic, index) => {
                return (
                  <div>
                    <TopicListTile key={index} topic={topic} />
                  </div>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <Box sx={{ height: "40px" }}></Box>
    </Box>
  );
}

export default AllTopicsPage;
