import React from "react";
import { Chip } from "@mui/material";

function CustomChip({ status }) {
  // color of the chip depending on status
  const chipColor = (status) => {
    switch (status) {
      case "resolved":
        return "#078667";

      case "Submitted":
        return "#757575";

      case "In progress":
        return "#757575";

      case "Pending confirmation":
        return "#F38729";

      case "Pending user's response":
        return "#F38729";

      default:
        return "#757575";
    }
  };
  return (
    <Chip
      label={status}
      size="small"
      sx={{
        justifyContent: "flex-end",
        alignSelf: "center",
        backgroundColor: chipColor(status),
        color: "white",
        fontFamily: "Open Sans",
        fontWeight: 600,
        // height: "20px",
        // marginTop: "16px",
        fontSize: "12px",
      }}
    />
  );
}

export default CustomChip;
