import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  FormHelperText,
} from "@mui/material";

const Checkboxes = ({
  //   isChecked1,
  //   setIsChecked1,
  isChecked2,
  setIsChecked2,
  onClickLink,
  error,
  helperText,
}) => {
  return (
    <FormGroup>
      {/* <FormControlLabel
        control={<Checkbox />}
        className="mt-3"
        label="Use this number for all future IT correspondences."
        checked={isChecked1}
        onChange={(e) => setIsChecked1(e.target.checked)}
      /> */}
      <FormControlLabel
        control={<Checkbox />}
        className="mt-3"
        checked={isChecked2}
        onChange={(e) => setIsChecked2(e.target.checked)}
        label={
          <span>
            I have read, understood and agree to be bound by{" "}
            <Link
              onClick={() => onClickLink()}
              // href="https://www.tech.gov.sg/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GovTech's Privacy Policy
            </Link>{" "}
            and agree that they may collect, use and disclose my personal data
            as provided in this form for the purposes set out in the Privacy
            Policy.
            {error && (
              <FormHelperText style={{ color: "#c73333" }}>
                {helperText}
              </FormHelperText>
            )}
          </span>
        }
      />
    </FormGroup>
  );
};

export default Checkboxes;
