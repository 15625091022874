import React, { useState } from "react";

import LoadingOverlay from "../components/loading_overlay";
import { CardContent, Card } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as workpalJS from "@workpal-build/workpaljs";
import axios from "axios";
import ReactGA from "react-ga4";
import config from "../../config";

function MSAuthenticatorSteps() {
  const { issueid } = useParams();
  const [clickedReset, setClickedReset] = useState(false);
  const { userapi, user } = useSelector((state) => state);
  const data = useSelector((state) => state.data);
  const topicIndex = useSelector((state) => state.selectedOptions.topicIndex);
  const issueIndex = useSelector((state) => state.selectedOptions.issueIndex);
  const dataList = data;
  const issueinfo = dataList[topicIndex].issues[issueIndex];

  const jwt = user.jwt;

  const styles = {
    unlinkButton: {
      backgroundColor: "transparent",
      color: "#376EEE",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 700,
      padding: 0,
      paddingTop: "5px",
      display: "flex",
      alignItems: "center",
    },
    paragraph: {
      display: "flex",
      paddingBottom: "10px",
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontSize: 16,
    },
    cardbg: {
      backgroundColor: "#F8F8F8",
      boxShadow: "none",
    },
    message: {
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 700,
      color: "#4D4D4D",
      paddingBottom: "10px",
    },
    title: {
      fontSize: "18px",
      fontFamily: "Open Sans",
      fontWeight: 700,
      color: "#1D1D1D",
    },
    content: {
      fontSize: "16px",
      fontFamily: "Open Sans",
      color: "#4D4D4D",
      fontWeight: 400,
    },
    okButton: {
      backgroundColor: "white",
      color: "#376EEE",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 700,
      padding: "10px 0px",
      margin: 0,
      width: "100%",
      display: "block",
    },
    proceedButton: {
      width: "100%",
      backgroundColor: "white",
      color: "#D0492B",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 700,
    },
    email: {
      fontSize: "16px",
      fontFamily: "Open Sans",
      color: "#4D4D4D",
      fontWeight: 700,
    },
    cancelButton: {
      width: "100%",
      backgroundColor: "white",
      color: "#4D4D4D",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
    },
  };

  // API call to reset MS authenticator
  const handleReset = async (e) => {
    e.preventDefault();
    setClickedReset(true);
    setOverlayMessage(resettingMessage);

    await workpalJS.refreshAccessToken();

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}msauth`,
        // {
        //   email: "htx-demouser1@qa.gcc.gov.sg",
        // } /*this email will give successful api call*/,

        { email: userapi.email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const resultCode = response.data.data.statuscode;
      // const resultCode = 509;

      if (resultCode == 200 || resultCode == 201) {
        setOverlayMessage(successfulMessage);
      } else if (resultCode == 502 || resultCode == 503 || resultCode == 504) {
        setOverlayMessage(sthWentWrongTryAgainMassage);
      } else {
        setOverlayMessage(sthWentWrongReportIssueMassage);
      }
    } catch (error) {
      const errorText = JSON.stringify(error.message);
      if (
        errorText.includes("502") ||
        errorText.includes("503") ||
        errorText.includes("504")
      ) {
        setOverlayMessage(sthWentWrongTryAgainMassage);
      } else {
        setOverlayMessage(sthWentWrongReportIssueMassage);
      }
    }
  };

  const resettingMessage = (
    <div style={styles.message}>Unlinking device(s)</div>
  );

  const successfulMessage = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Avatar sx={{ bgcolor: "#078667", margin: "15px 0" }}>
        <CheckIcon sx={{ color: "white" }} />
      </Avatar>
      <div style={styles.title}>Successfully unlinked!</div>
      <p style={styles.content}>Please proceed to step 2.</p>
      <div style={{ borderTop: "1px #D0D0D0 solid", width: "100%" }} />
      <button
        style={styles.okButton}
        onClick={() => {
          setClickedReset(false);
          setOverlayMessage(initMessage);
          //Google Analytics to track user successfully reset MS authenticator
          ReactGA.gtag("event", "MFA - Execute", {
            result: "Success",
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
        }}
      >
        OK
      </button>
    </div>
  );

  // Something went wrong try again massage
  const sthWentWrongTryAgainMassage = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Avatar sx={{ bgcolor: "#C73333", margin: "15px 0" }}>
        <PriorityHighRoundedIcon />
      </Avatar>
      <div style={styles.title}>Something went wrong</div>
      <div style={{ ...styles.content, padding: "5pax 10px" }}>
        Please try again in a few minutes.
      </div>
      <p style={{ ...styles.content, padding: "5px 10px" }}>
        If the unlink function still does not work, report the issue to us
        instead.
      </p>
      <div style={{ borderTop: "1px #D0D0D0 solid", width: "100%" }} />
      <button
        style={styles.okButton}
        onClick={() => {
          setClickedReset(false);
          setOverlayMessage(initMessage);
          //Google Analytics to track user fail to reset MS authenticator
          ReactGA.gtag("event", "MFA - Execute", {
            result: "Unsuccessful",
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
        }}
      >
        OK
      </button>
    </div>
  );
  // Something went wrong report issue massage
  const sthWentWrongReportIssueMassage = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Avatar sx={{ bgcolor: "#C73333", margin: "15px 0" }}>
        <PriorityHighRoundedIcon />
      </Avatar>
      <div style={styles.title}>Something went wrong</div>
      <p style={styles.content}>Please report the issue instead.</p>
      <div style={{ borderTop: "1px #D0D0D0 solid", width: "100%" }} />
      <Link
        to={`/report_incident_form/${issueinfo.topicid}/${issueid}`}
        state={{ source: "msAuthenticator" }}
        style={styles.okButton}
        onClick={() => {
          setClickedReset(false);
          setOverlayMessage(initMessage);
          //Google Analytics to track user fail to reset MS authenticator and report IT issue
          ReactGA.gtag("event", "MFA - Report Issue", {
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
        }}
      >
        Report IT issue
      </Link>
      <div style={{ borderTop: "1px #D0D0D0 solid", width: "100%" }} />
      <button
        style={styles.cancelButton}
        onClick={() => {
          setClickedReset(false);
          setOverlayMessage(initMessage);
          //Google Analytics to track user fail to reset MS authenticator
          ReactGA.gtag("event", "MFA - Execute", {
            result: "Unsuccessful",
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
        }}
      >
        Close
      </button>
    </div>
  );

  // initial message in modal
  const initMessage = (
    <div style={{ textAlign: "left" }}>
      <div style={{ padding: "0px 15px", paddingTop: "20px" }}>
        <div style={{ ...styles.title, paddingBottom: "10px" }}>
          Proceed to unlink?
        </div>
        <div style={styles.content}>
          You are unlinking existing Authenticator device(s) tied to the WOG ID:
        </div>
        <div style={{ ...styles.email, padding: "10px 0px" }}>
          {userapi.email}
        </div>
        <div style={{ ...styles.content, paddingBottom: "15px" }}>
          After that, you will have to set up the Authenticator on your
          device(s) again.
        </div>
      </div>

      <div style={{ borderTop: "1px #D0D0D0 solid" }} />
      <button type="button" style={styles.proceedButton} onClick={handleReset}>
        Proceed
      </button>
      <div style={{ borderTop: "1px #D0D0D0 solid" }} />
      <button
        style={styles.cancelButton}
        onClick={() => {
          setClickedReset(false);
        }}
      >
        Cancel
      </button>
    </div>
  );
  const [overlayMessage, setOverlayMessage] = useState(initMessage);

  return (
    <div>
      {" "}
      <Card sx={{ minWidth: 275 }} style={styles.cardbg}>
        <LoadingOverlay isOpen={clickedReset} message={overlayMessage} />
        <CardContent>
          <div style={styles.paragraph}>Follow steps 1 to 4 below:</div>
          <div style={styles.paragraph}>
            <div>1. </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingLeft: "5px",
              }}
            >
              <div>
                Unlink existing device(s) tied to the Authenticator settings for
                your WOG ID. You can do this via Workpal.
              </div>
              <button
                style={styles.unlinkButton}
                onClick={() => {
                  setClickedReset(true);
                  //Google Analytics to track user click on "unlink now"
                  ReactGA.gtag("event", "MFA - Initiate", {
                    sessionIdCustom: userapi.sessionID,
                    userId: userapi.userSysId,
                    agency: userapi.agency,
                    channel: "Mobile",
                    timestamp: Date(),
                  });
                }}
              >
                <div style={{ paddingRight: "5px" }}>Unlink now</div>
                {<ArrowForwardIosRounded fontSize="5px" />}
              </button>
            </div>
          </div>

          <div style={styles.paragraph}>
            <div>2. </div>
            <div style={{ paddingLeft: "5px" }}>
              After unlinking the device(s), go to this URL on your GSIB work
              laptop:
              <p style={{ margin: 0, fontWeight: 500 }}>
                https://mysignins.microsoft.com/security-info/
              </p>
            </div>
          </div>

          <div style={styles.paragraph}>
            <div>3. </div>
            <div style={{ paddingLeft: "5px" }}>
              Add Authenticator app as a sign-in method.
            </div>
          </div>

          <div style={styles.paragraph}>
            <div>4. </div>
            <div style={{ paddingLeft: "5px" }}>
              Follow the instructions on the screen to set up Microsoft
              Authenticator on your device.
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default MSAuthenticatorSteps;
