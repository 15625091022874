import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import Headerbar from "./components/header";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storeSelectedIssue,
  storeSelectedTopicIndex,
  storeSelectedIssueIndex,
} from "../state/action_creators";
import ReactGA from "react-ga4";

const styles = {
  list: {
    width: "100%",
    padding: 0,
    bgcolor: "background.paper",
  },
  listitem: { paddingTop: "16px", paddingBottom: "15px" },
  divider: {
    borderColor: "#D0D0D0",
    borderBottomWidth: "1.2px",
  },
};

const IssueListPage = () => {
  const { topicId } = useParams();

  const [issueList, setIssueList] = useState([]);

  const topicIssueList = useSelector((state) => state.data);

  const userapi = useSelector((state) => state.userapi);

  const dispatch = useDispatch();

  const [topicIndex, setTopicIndex] = useState(0);

  useEffect(() => {
    const index = topicIssueList.findIndex((item) => item.topic === topicId);
    const issues = topicIssueList[index].issues;
    setTopicIndex(index);
    if (issues) {
      setIssueList(issues);
      dispatch(storeSelectedTopicIndex(index));
    } else {
      setIssueList([]); // or setIssueList(null) or any other appropriate value
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [topicId]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();
  const clickIssueHandler = (name, idd, link, issueId) => {
    // alert(link);
    dispatch(storeSelectedIssue(name));
    dispatch(storeSelectedIssueIndex(idd));

    //Google Analytic
    ReactGA.gtag("event", "Tap Issue", {
      issueName: name,
      issueId: issueId,
      topicName: topicId,
      topicId: topicIssueList[topicIndex].id,
      sessionIdCustom: userapi.sessionID,
      userId: userapi.userSysId,
      agency: userapi.agency,
      channel: "Mobile",
      timestamp: Date(),
    });
    // ReactGA.event({
    //   category: name, //issue
    //   action: topicId, //topic
    //   label: `Timestamp ${Date()}`,
    // });

    navigate(link);
  };

  return (
    <>
      <Headerbar title={topicId} />
      <div style={{ paddingTop: "55px" }}></div>
      <Box
        sx={{ width: "100%", height: "100vh", backgroundColor: "#F8F8F8" }}
        noValidate
        autoComplete="off"
      >
        <List sx={styles.list} component="nav">
          {issueList.map((issue, idd) => (
            <ListItem
              key={issue.id}
              to={`/issue_info_page/${issue.id}`}
              component={Link}
              sx={styles.listitem}
              divider
              style={{ textDecoration: "none", color: "#1D1D1D" }}
              onClick={(e) => {
                e.preventDefault();
                clickIssueHandler(
                  issue.name,
                  idd,
                  topicId === "Others" && issue.name === "Others"
                    ? `/report_incident_form/${topicId}/${issue.id}`
                    : `/issue_info_page/${issue.id}`,
                  issue.id
                );
              }}
            >
              <ListItemText primary={issue.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default IssueListPage;
