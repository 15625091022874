import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import * as workpalJS from "@workpal-build/workpaljs";
import CloseIcon from "@mui/icons-material/Close";
import CloseAppCross from "../../components/close_app_cross.tsx";

function HomepageBanner() {
  return (
    <Stack style={{ position: "relative" }}>
      <Box style={{ width: "100%" }}>
        <img
          src={require("../../../img/HeaderImage.png")}
          alt="homeBanner"
          style={{ width: "100%" }}
        />{" "}
      </Box>
      <Box
        style={{
          display: "flex",
        }}
      >
        <CloseAppCross />

        <Typography
          variant="h6"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0)",
            top: "15px",
            fontFamily: "Open Sans",
            fontWeight: 700,
            fontSize: 17,
          }}
        >
          IT Support
        </Typography>
      </Box>
    </Stack>
  );
}

export default HomepageBanner;
