export const fetchUserApiInfo = (data) => {
  return (dispatch) => {
    dispatch({
      type: "storeUserInfoAPI",
      payload: data,
    });
  };
};
export const fetchTopicIssues = (data) => {
  return (dispatch) => {
    dispatch({
      type: "storeTopicIssueList",
      payload: data,
    });
  };
};

export const searchArr = (word) => {
  return (dispatch) => {
    dispatch({
      type: "search",
      payload: word,
    });
  };
};

export const storeUserJWT = (jwt) => {
  return (dispatch) => {
    dispatch({
      type: "storeJWT",
      payload: jwt,
    });
  };
};

export const storeSelectedTopic = (topic) => {
  return (dispatch) => {
    dispatch({
      type: "storeTopic",
      payload: topic,
    });
  };
};

export const storeSelectedIssue = (issue) => {
  return (dispatch) => {
    dispatch({
      type: "storeIssue",
      payload: issue,
    });
  };
};

// export const storeRelatedIssuesList = (issuesList) => {
//   return (dispatch) => {
//     dispatch({
//       type: "storeRelatedIssueList",
//       payload: issuesList,
//     });
//   };
// };

export const storeSelectedTopicIndex = (index) => {
  return (dispatch) => {
    dispatch({
      type: "storeTopicIndex",
      payload: index,
    });
  };
};

export const storeSelectedIssueIndex = (index) => {
  return (dispatch) => {
    dispatch({
      type: "storeIssueIndex",
      payload: index,
    });
  };
};

export const storeUserDeviceInfo = (info) => {
  return (dispatch) => {
    dispatch({
      type: "storeUserDeviceInfo",
      payload: info,
    });
  };
};

export const storeUserEmail = (email) => {
  return (dispatch) => {
    dispatch({
      type: "storeUserEmail",
      payload: email,
    });
  };
};

export const setLoading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: "setLoading",
      payload: isLoading,
    });
  };
};
