import React from "react";
import PropTypes from "prop-types";
import { Box, AppBar, Toolbar } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Headerbar({ title, closeOption }) {
  const navigate = useNavigate();

  const handlePageClose = () => {
    navigate(-1);
  };
  const back = closeOption ? false : true;
  const close = closeOption ? true : false;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className="appHeader">
        <Toolbar sx={{ justifyContent: "center" }}>
          {back && (
            <ArrowBack
              onClick={handlePageClose}
              sx={{ position: "absolute", left: "16px" }}
            />
          )}

          {close && (
            <ArrowBack
              onClick={() => closeOption()}
              sx={{ position: "absolute", left: "16px" }}
            />
          )}
          <div style={{ fontSize: "17px", fontWeight: 600 }}>{title}</div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Headerbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Headerbar;
