import React from "react";
import { ListItem, ListItemText } from "@mui/material";

function DateTile({ date }) {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "short" });
    return month + " " + year;
  }

  const newDate = formatDate(date);

  return (
    <ListItem
      sx={{
        background: "#F8F8F8",
      }}
    >
      <ListItemText
        primary={newDate}
        primaryTypographyProps={{
          color: "#4D4D4D",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "16px",
        }}
      />
    </ListItem>
  );
}
export default DateTile;
