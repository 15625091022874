import React from "react";
import HomepageBanner from "../landing_page/components/homepage_banner";
import { Button, Typography } from "@mui/material";

function ServerNoFoundPage() {
  const style = {
    button: {
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: 16,
      color: "#FFFFFF",
    },
    title: {
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: 16,
      color: "#4D4D4D",
      paddingTop: "20px",
    },
    content: {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: 16,
      color: "#4D4D4D",
      paddingTop: "20px",
    },
  };
  return (
    <div style={{ textAlign: "center" }}>
      <HomepageBanner />
      {/* <img src={require("../img/../next-step.png")}></img> */}
      <img
        style={{ paddingTop: "50px" }}
        src={require("../../img/ErrorGraphics.png")}
        alt="homeBanner"
      />

      <Typography style={style.title}>Something went wrong</Typography>
      <div
        style={{
          margin: "15px",
          position: "absolute",
          width: "94%",
          bottom: "5%",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          disableElevation
          className="mt-3"
          sx={style.button}
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </Button>
      </div>
    </div>
  );
}

export default ServerNoFoundPage;
