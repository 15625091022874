import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function TopicsOptions({ topics, handleTopicSelect, selected }) {
  const topicList = [];
  topics.map((eachTopic) =>
    topicList.push({
      topic: eachTopic.topic,
      icon: eachTopic.icon,
    })
  );
  const withoutOthers = topicList.filter((e) => e.topic != "Others");

  const sortedList = withoutOthers.sort((a, b) => {
    return a.topic.localeCompare(b.topic, undefined, { sensitivity: "base" });
  });

  const grouping = (arr) => {
    return arr.reduce((accVal, currVal) => {
      var key = currVal.topic.charAt(0);
      if (!accVal[key]) {
        accVal[key] = [];
      }
      accVal[key].push(currVal);
      return accVal;
    }, {});
  };
  const groupedData = grouping(sortedList);
  // alert(JSON.stringify(groupedData));

  const othersicon = topicList.filter((e) => e.topic == "Others")[0].icon;
  groupedData["Topic not listed?"] = [{ topic: "Others", icon: othersicon }];

  const styles = {
    title: {
      background: "#F8F8F8",
      color: "#4D4D4D",
      fontSize: "16px",
      fontWeight: 700,
      padding: "12px",
      position: "sticky",
      top: 55,
      zIndex: 1,
    },
    fontStyle: {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      textDecoration: "none",
      lineHeight: "20px",
      paddingLeft: "16px",
    },
    topicDivider: {
      background: "#D0D0D0",
      borderBottomWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.35)",
    },
  };

  return (
    <Box>
      {Object.keys(groupedData).map((key, i) => {
        return (
          <Box key={i}>
            <Box sx={styles.title}>{key}</Box>
            {groupedData[key].map((topic, index) => {
              return (
                <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Link>
                    <ListItem sx={{ padding: "16px" }}>
                      <ListItemAvatar>
                        <img
                          src={`${topic.icon}`}
                          alt={topic.topic}
                          style={{
                            width: "48px",
                            display: "flex",
                            margin: "auto",
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={topic.topic}
                        primaryTypographyProps={styles.fontStyle}
                        onClick={() => handleTopicSelect(topic.topic)}
                      />
                      {selected === topic.topic && (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.345 7.2608C22.0295 7.72749 22.2061 8.66068 21.7394 9.34515L14.2394 20.3451C13.9762 20.7311 13.5483 20.9724 13.0819 20.9979C12.6155 21.0234 12.1638 20.8301 11.8601 20.4752L7.36015 15.2143C6.82166 14.5848 6.89547 13.6379 7.52501 13.0994C8.15454 12.5609 9.10142 12.6347 9.63991 13.2643L12.8653 17.035L19.2607 7.65514C19.7274 6.97067 20.6606 6.79412 21.345 7.2608Z"
                              fill="#376EEE"
                            />
                          </svg>
                        </div>
                      )}
                    </ListItem>
                    <Divider sx={styles.topicDivider} />
                  </Link>
                </List>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

export default TopicsOptions;
