import React, { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import TicketListPage from "../ticket_list_page";
import RequestIncidentForm from "../report_incident_form";
import ChatPage from "../chat_page";
import ConfirmationPage from "../incident_form_components/confirmation_page";
import NoIssueConfirmPage from "../no_issue_confirm_page";
import IncidentDetailPage from "../incident_detail_page/incident_detail_page";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import IssueInfoPage from "../issue_info_page";
import IssueListPage from "../issue_list";
import LandingPage from "../landing_page/landing_page";
import AllTopicsPage from "../all_topics_page/all_topics_page";
import AllRelatedArticlesPage from "../all_related_articles_page";
import RelatedArticlesInfoPage from "../related_articles_info_page";
import OnBoardingPage from "../onboarding_page";
import ServerNoFoundPage from "../error_handling_page/server_not_found_page";
import * as workpalJS from "@workpal-build/workpaljs";

function AnimatedRoutes() {
  // Redux as state management
  const state = useSelector((state) => {
    return state;
  });

  const location = useLocation();
  const [isOnboarded, setIsOnboarded] = useState(() => {
    const savedOnboardingStatus = localStorage.getItem(
      "viewOnboardingTutorial"
    );
    return savedOnboardingStatus || false;
  });

  const { pathname } = location;

  const navigate = useNavigate();
  useEffect(() => {
    workpalJS.listener.registerBackpressedListener(function () {
      //TODO : Handle navigation stack callback

      if (pathname === "/confirmation_page") {
        navigate("/");
      } else {
        navigate(-1);
      }
      if (location.key === "default" || pathname === "/") {
        workpalJS.closeWebView();
      }
    });
  }, [location]);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            isOnboarded === "true" ? (
              <LandingPage />
            ) : (
              <Navigate to="/onboarding" />
            )
          }
        />
        <Route path="/alltopics" element={<AllTopicsPage />} />
        <Route path="/history" element={<TicketListPage />} />
        <Route path="/onboarding" element={<OnBoardingPage />} />
        <Route path="/servererror" element={<ServerNoFoundPage />} />

        <Route
          path="/report_incident_form/:topicId?/:issueid?"
          element={<RequestIncidentForm />}
        />
        <Route path="/chat_page" element={<ChatPage />} />
        <Route path="/issue_info_page/:issueid" element={<IssueInfoPage />} />
        <Route path="/issue_list/:topicId" element={<IssueListPage />} />
        <Route
          path="/allrelatedarticles/:topic/:issue"
          element={<AllRelatedArticlesPage />}
        />
        <Route
          path="/relatedarticles/:index"
          element={<RelatedArticlesInfoPage />}
        />
        <Route path="/confirmation_page" element={<ConfirmationPage />} />
        <Route
          path="/no_issue_confirmation_page"
          element={<NoIssueConfirmPage />}
        />
        <Route
          path="/incident_detail_page/:id"
          exact
          element={<IncidentDetailPage data={state.testing} />}
        />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
