import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const CustomFormControl = ({ label, value, onClick, disabled = false, error, helperText }) => {
  return (
    <FormControl
      sx={{
        width: "100%",
        marginTop: "16px",
      }}
      variant="standard"
      disabled={disabled}
      onClick={onClick}
      error={error}
      // helperText={helperText}
    >
      <InputLabel shrink={value ? true : false}>{label}</InputLabel>
      <Input
        type="text"
        value={value || null}
        endAdornment={
          <InputAdornment position="end">
            <IconButton sx={{ paddingRight: "0" }}>
              <ArrowDropDown />
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText style={{color: "#c73333"}}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomFormControl;
