import React from "react";
import { useState } from "react";
import {
  Button,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function NoIssueConfirmPage() {
  // This handle the opening anf closing of the loading spinning icon
  const [openBackgroundSpinner, setOpenBackgroundSpinner] = useState(false);
  const handleCloseBgSpinner = () => {
    setOpenBackgroundSpinner(false);
  };
  const handleOpenBgSpinner = () => {
    setOpenBackgroundSpinner(true);
  };

  //This to delay navigating to the successful page
  const history = useNavigate();
  const delayNavigation = (e, path) => {
    e.preventDefault();
    handleOpenBgSpinner();
    setTimeout(() => {
      history(path);
      handleCloseBgSpinner();
    }, 1500);
  };

  const styles = {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    },
    image: {
      marginTop: "4em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      borderRadius: "50%",
    },
    title: {
      marginTop: "2em",
      textAlign: "center",
      fontWeight: 600,
    },
    description: {
      margin: "auto",
      marginTop: "1em",
      marginBottom: "2em",
      textAlign: "center",
      maxWidth: "85%",
      fontSize: "14px",
    },
    bottomButton: {
      background: "white",
      height: "100px",
      width: "-webkit-fill-available",
      position: "fixed",
      bottom: 0,
      left: 0,
      padding: "16px",
      textDecoration: "none",
    },
  };
  return (
    <div style={styles.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackgroundSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <div style={styles.image}>
          <img
            src={require("../img/success.png")}
            alt="success"
            style={styles.img}
          />
        </div>
        <Typography variant="h6" sx={styles.title}>
          Confirmation Received!
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          We’re glad to hear that you no longer experience this issue.
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          We’ll proceed to close this incident.
        </Typography>
        <Link
          to="/"
          style={styles.bottomButton}
          onClick={(e) => {
            delayNavigation(e, "/");
          }}
        >
          <Button variant="outlined" fullWidth disableElevation>
            Close
          </Button>
        </Link>
      </Container>
    </div>
  );
}

export default NoIssueConfirmPage;
