const config = {
  // UAT
  // API_BASE_URL:
  //   "https://vxpw82onl4.execute-api.ap-southeast-1.amazonaws.com/uat/",
  // API_UPLOAD_URL:
  //   "https://vxpw82onl4.execute-api.ap-southeast-1.amazonaws.com/uat/attachment",

  // PROD
  API_BASE_URL:
    "https://vxpw82onl4.execute-api.ap-southeast-1.amazonaws.com/prod/",
  API_UPLOAD_URL:
    "https://vxpw82onl4.execute-api.ap-southeast-1.amazonaws.com/prod/attachment",
};
export default config;
