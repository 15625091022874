import React from "react";
import { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

function NoLongerFaceIssueButton() {
  const [open, setOpen] = useState(false);
  const [openBackgroundSpinner, setOpenBackgroundSpinner] = useState(false);

  // This handle the open and closing of the confirmation dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if ((reason === "backdropClick") | "escapeKeyDown") {
      console.log(reason);
    } else {
      if (event.target.value === "agree") {
        setOpen(false);
      } else {
        setOpen(false);
      }
    }
    // setOpen(false);
  };

  // This handle the opening anf closing of the loading spinning icon
  const handleCloseBgSpinner = () => {
    setOpenBackgroundSpinner(false);
  };
  const handleOpenBgSpinner = () => {
    setOpenBackgroundSpinner(true);
  };

  //This to delay navigating to the successful page
  const history = useNavigate();
  const delayNavigation = (e, path) => {
    e.preventDefault();
    handleOpenBgSpinner();
    handleClose(e);
    setTimeout(() => {
      history(path);
      handleCloseBgSpinner();
    }, 1500);
  };

  //Dialog styling
  const styledPaper = styled(Paper)`
    border-radius: 14px;
    justify-content: space-around;
  `;

  const styles = {
    root: {
      background: "white",
      height: "100px",
      width: " -webkit-fill-available",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "16px",
    },
    dialogTitle: {
      textAlign: "center",
      fontFamily: "SF Pro Text",
      fontWeight: 600,
      fontSize: "17px",
      paddingBottom: "0px",
      paddingTop: "21px",
    },
    dialogActions: {
      justifyContent: "space-around",
      padding: 0,
      borderTop: "0.5px solid #3C3C43",
    },
    dialogContentText: {
      textAlign: "center",
      fontFamily: "SF Pro Text",
      fontWeight: 400,
      fontSize: "13px",
      paddingBottom: "0px",

      color: "black",
    },
    dialogleftButton: {
      textDecoration: "none",
      width: "50%",
      backgroundColor: "#fff",
      borderRadius: 0,
      padding: "10.5px",
      fontFamily: "SF Pro Text",
      fontWeight: 400,
      color: "#007AFF",
      fontSize: "17px",
      display: "flex",
      justifyContent: "center",
    },
    dialogRightButton: {
      textDecoration: "none",
      width: "50%",
      backgroundColor: "#fff",
      borderLeft: "0.5px solid #3C3C43",
      borderRadius: 0,
      padding: "10.5px",
      fontFamily: "SF Pro Text",
      fontWeight: 600,
      color: "#FF3B30",
      fontSize: "17px",
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <Box sx={styles.root}>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        I no longer face this issue
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackgroundSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={styledPaper}
      >
        <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
          {"Are you sure you no longer experience this issue?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={styles.dialogContentText}
          >
            Tapping ‘Confirm’ will close this incident.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.dialogActions}>
          <Link onClick={handleClose} style={styles.dialogleftButton}>
            Cancel
          </Link>
          <Link
            onClick={(e) => {
              delayNavigation(e, "/no_issue_confirmation_page");
            }}
            style={styles.dialogRightButton}
          >
            Confirm
          </Link>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default NoLongerFaceIssueButton;
