import React from "react";
import {
  Box,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import CustomChip from "../components/custom_chip.component";

function ListTile({ ticketNumber, status, category, issue }) {
  return (
    <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
      <Link
        to={`/incident_detail_page/${ticketNumber}`}
        style={{ textDecoration: "none" }}
      >
        <ListItem disablePadding>
          <ListItemButton
            // component="a"
            //   href="https://www.google.com"
            sx={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <Stack width="100%">
              <Box sx={{ display: "flex" }}>
                <ListItemText
                  primary={`INC000000${ticketNumber}`}
                  primaryTypographyProps={{
                    color: "#4D4D4D",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    textDecoration: "none",
                    lineHeight: "20px",
                  }}
                />
                <CustomChip status={status} />
              </Box>
              <ListItemText
                secondary={`${category} ${category === "" ? "" : ">"} ${issue}`}
                secondaryTypographyProps={{
                  color: "#4D4D4D",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
        <Divider
          style={{
            background: "#D0D0D0",
            borderBottomWidth: "1px",
            borderColor: "rgba(0, 0, 0, 1)",
          }}
        />
      </Link>
    </List>
  );
}

export default ListTile;
