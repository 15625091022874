import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { Avatar, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";

const OnBoardingPage = () => {
  const style = {
    title: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: 24,
      color: "#4D4D4D",
      paddingTop: "20px",
    },
    content: {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: 16,
      color: "#4D4D4D",
      paddingTop: "20px",
    },
    button: {
      height: "23vh",
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
    },
    slidesContainer: { position: "relative", height: "75vh" },
    avatar: {
      bgcolor: "#F8F8F8",
      height: "200px",
      width: "200px",
      margin: "auto",
      marginTop: "20vh",
    },
    img: {
      display: "flex",
      margin: "auto",
    },
  };

  // local storage to identify if user went through the onboarding tutorial
  const handleClick = () => {
    localStorage.setItem("viewOnboardingTutorial", true);
    /* for testing purpose -> to remove local storage: */
    // localStorage.removeItem("viewOnboardingTutorial");
  };

  return (
    <div style={{ height: "100vh", padding: "0px 16px" }}>
      <Helmet>
        <script src="https://assets.dcube.cloud/scripts/wogaa.js"></script>
      </Helmet>
      <div style={style.slidesContainer}>
        <Swiper pagination={true} modules={[Pagination]}>
          <SwiperSlide>
            <div
              style={{
                height: "70%",
              }}
            >
              <Avatar sx={style.avatar}>
                {" "}
                <img
                  src={require("../img/Onboarding-1.svg").default}
                  alt="Onboarding"
                  style={style.img}
                />
              </Avatar>
              <Typography style={style.title}>IT Support on-the-go</Typography>
              <Typography style={style.content}>
                Access help content or report an issue even if you don't have
                your work device
              </Typography>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              style={{
                height: "70%",
              }}
            >
              <Avatar sx={style.avatar}>
                <img
                  src={require("../img/Onboarding-2.svg").default}
                  alt="Onboarding"
                  style={{
                    display: "flex",
                    margin: "auto",
                  }}
                />
              </Avatar>
              <Typography style={style.title}>Easy self-help</Typography>
              <Typography style={style.content}>
                Find help content easily to resolve issues quickly on your own
              </Typography>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              style={{
                height: "70%",
              }}
            >
              <Avatar sx={style.avatar}>
                <img
                  src={require("../img/Onboarding-3.svg").default}
                  alt="Onboarding"
                  style={style.img}
                />
              </Avatar>
              <Typography style={style.title}>
                First stop for all your IT issues
              </Typography>
              <Typography style={style.content}>
                Connect with the relevant team to address your IT issue
              </Typography>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div style={style.button}>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          href="/"
          onClick={handleClick()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
export default OnBoardingPage;
