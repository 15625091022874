import React from "react";
import * as workpalJS from "@workpal-build/workpaljs";
import CloseIcon from "@mui/icons-material/Close";

interface CloseAppCrossProps {}

interface Style {
  icon: React.CSSProperties;
}

const CloseAppCross: React.FC<CloseAppCrossProps> = () => {
  const style: Style = {
    icon: {
      position: "absolute",
      left: "5%",
      top: "15px",
    },
  };

  return (
    <div>
      <CloseIcon
        style={style.icon}
        onClick={() => {
          workpalJS.closeWebView();
        }}
      />
    </div>
  );
};

export default CloseAppCross;
