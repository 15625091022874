const initialState = {
  topic: "",
  issue: "",
  topicIndex: NaN,
  //   issuesList: [],
  issueIndex: NaN,
};

const selectedOptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "storeTopic":
      return { ...state, topic: payload };
    case "storeIssue":
      return { ...state, issue: payload };
    // case "storeRelatedIssueList":
    //   return { ...state, issueList: payload };
    case "storeTopicIndex":
      return { ...state, topicIndex: payload };
    case "storeIssueIndex":
      return { ...state, issueIndex: payload };
    default:
      return state;
  }
};

export default selectedOptionsReducer;
