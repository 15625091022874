import React from "react";

function RatingComponent() {
  return (
    <div>
      <h6 className="detailContent">
        That's great! We'll proceed to close the incident for you.
      </h6>

      <h6 className="detailContent">
        How would you rate your overall experience?
      </h6>
    </div>
  );
}

export default RatingComponent;
