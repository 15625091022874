import data from "../../data.json";

const initialState = {
  history: data.data,
};

const ticketReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "storeTicketHistory":
      return { ...state, history: payload };

    default:
      return state;
  }
};

export default ticketReducer;
