import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "0.5em",
  },
  image: {
    marginTop: "4em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    borderRadius: "50%",
  },
  title: {
    marginTop: "2em",
    textAlign: "center",
    fontWeight: 600,
  },
  description: {
    margin: "auto",
    marginTop: "1em",
    marginBottom: "2em",
    textAlign: "center",
    maxWidth: "85%",
    fontSize: "14px",
  },
};

const ConfirmationPage = () => {
  const location = useLocation();
  const { incidentNumber, emailAddress } = location.state;

  return (
    <div style={styles.root}>
      <Container maxWidth="sm">
        <div style={styles.image}>
          <img
            src={require("../../img/success.png")}
            alt="success"
            style={styles.img}
          />
        </div>
        <Typography variant="h5" sx={styles.title}>
          Reported!
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          You have successfully reported an issue. Your ticket number is{" "}
          <Typography
            component="span"
            variant="body1"
            sx={{ fontWeight: 600, fontSize: 14 }}
          >
            {incidentNumber}
          </Typography>
          .
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          An email confirmation has also been sent to{" "}
          {emailAddress.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          You can also view and track updates on your ticket via the email.
        </Typography>
        <Button variant="outlined" fullWidth disableElevation href="/">
          Back to IT Support Home
        </Button>
      </Container>
    </div>
  );
};

export default ConfirmationPage;
