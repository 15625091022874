import { createTheme } from "@mui/material/styles";

const commonButtonStyles = {
  borderRadius: "50px",
  textTransform: "none",
  fontWeight: 700,
  minWidth: 64,
  padding: "10px 16px",
  fontSize: "16px",
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...commonButtonStyles,
          backgroundColor: "#376EEE ",
          color: "#fff ",
        },
        outlined: {
          ...commonButtonStyles,
          borderColor: "#376eee",
          borderWidth: "2px",
          color: "#376EEE",
          backgroundColor: "transparent",
        },
        text: {
          ...commonButtonStyles,
          borderWidth: "2px",
          color: "#376EEE",
          backgroundColor: "transparent",
        },
      },
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#4d4d4d",
          "& a": {
            color: "#376eee",
            textDecoration: "none",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#4d4d4d",
        },
        asterisk: {
          display: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          color: "#4d4d4d",
        },
        h5: {
          fontSize: "20px",
          fontWeight: 700,
          letterSpacing: "0.15px",
        },
        h6: {
          fontSize: "16px",
          fontWeight: 600,
        },
        h7: {
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
        },
        caption: {
          color: "#757575",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        fullWidth: {
          borderBottomWidth: "9px",
        },
      },
    },
  },
  typography: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Open sans",
    fontColor: "#4D4D4D",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          // color: "#376eee",
          textDecoration: "none",
        },
      },
    },
  },
});

export default theme;
