import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import IncidentDetail from "./detail/incident_detail";
// import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  styled,
  Divider,
  Badge,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Headerbar from "../components/header";
import ChatPage from "../chat_page";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

function IncidentDetailPage({ data }) {
  const { id } = useParams();

  //redux
  const state = useSelector((state) => {
    return state;
  });
  const { ticket } = state;
  const [details, setDetails] = useState(null);
  const [status, setStatus] = useState(null);

  // on load, filter data in redux store base on id
  useEffect(() => {
    console.log(state);
    const details = ticket.history.find(
      (item) => String(item.id) === String(id)
    );
    if (details) {
      const { status } = details;
      setDetails(details);
      setStatus(status);
    }
  }, [id, state, ticket]);

  // For switching between tabs
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      backgroundColor: "#376EEE",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      marginRight: theme.spacing(1),
      color: "#757575",
      "&.Mui-selected": {
        color: "#376EEE",
        fontWeight: 700,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  //when user has pending response at the activity tab
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: -2,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: "0px 0px",
      fontSize: "10px",
      backgroundColor: "#B3261E",
      color: "white",
    },
  }));

  const styles = {
    lineDivider: {
      background: "#D0D0D0",
      borderBottomWidth: "1px",
      borderColor: "rgba(0, 0, 0, 1)",
    },
  };
  // if no details will have a spinner
  if (!details) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  // if have details will show the incident details
  return (
    <motion.div
      sx={{ bgcolor: "background.paper" }}
      initial={{ x: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.outerWidth, transition: { duration: 0.3 } }}
    >
      <Headerbar title={"Ticket Details"} />
      <StyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <StyledTab label="Details" {...a11yProps(0)} />

        <StyledTab
          label={
            <StyledBadge
              badgeContent={"!"}
              invisible={status === "Pending user's response" ? false : true}
            >
              Activity
            </StyledBadge>
          }
          {...a11yProps(1)}
        />
      </StyledTabs>
      <Divider style={styles.lineDivider} />

      {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <IncidentDetail details={details} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <ChatPage />
      </TabPanel>
      {/* </SwipeableViews> */}
    </motion.div>
  );
}

export default IncidentDetailPage;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
