import { Box, List, ListItem, ListItemText } from "@mui/material";
import React from "react";

function IssuesOptions({ issues, handleIssueSelect, selected }) {
  const styles = {
    list: {
      width: "100%",
      padding: 0,
      bgcolor: "background.paper",
    },
    listitem: { paddingTop: "16px", paddingBottom: "15px" },
    divider: {
      borderColor: "#D0D0D0",
      borderBottomWidth: "1.2px",
    },
  };

  return (
    <Box>
      <List sx={styles.list} component="nav">
        {issues.map((issue, index) => (
          <div key={index}>
            <ListItem sx={styles.listitem} divider>
              <ListItemText
                primary={issue.name}
                style={{ color: "#1D1D1D" }}
                onClick={() => handleIssueSelect(issue.id, issue.name)}
              />
              {selected === issue.name && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.345 7.2608C22.0295 7.72749 22.2061 8.66068 21.7394 9.34515L14.2394 20.3451C13.9762 20.7311 13.5483 20.9724 13.0819 20.9979C12.6155 21.0234 12.1638 20.8301 11.8601 20.4752L7.36015 15.2143C6.82166 14.5848 6.89547 13.6379 7.52501 13.0994C8.15454 12.5609 9.10142 12.6347 9.63991 13.2643L12.8653 17.035L19.2607 7.65514C19.7274 6.97067 20.6606 6.79412 21.345 7.2608Z"
                      fill="#376EEE"
                    />
                  </svg>
                </div>
              )}
            </ListItem>
          </div>
        ))}
      </List>
    </Box>
  );
}

export default IssuesOptions;
