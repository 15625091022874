import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

function OtherTopics() {
  const { userapi } = useSelector((state) => state);
  // MUI styling
  const styles = {
    page: {
      padding: "16px",
    },
    title: {
      fontFamily: "Open Sans",
      color: "#4D4D4D",
      fontWeight: 700,
      fontSize: 16,
      display: "flex",
    },
    gridItem: {
      height: "30vw",
      // background: "#F8F8F8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "flex-end",
      justifyContent: "center",
      overflow: "hidden",
      fontSize: 12,
      color: "black",
      paddingLeft: "8px",
      paddingBottom: "8px",
      marginBottom: "80px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.30)",
      borderRadius: "4px",
    },
  };
  return (
    <Box sx={styles.page}>
      <h1 style={styles.title}>All Topics</h1>
      <Link
        to={`/alltopics`}
        onClick={() => {
          //Google Analytics
          ReactGA.gtag("event", "Tap View All Topics", {
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
        }}
      >
        <Box sx={styles.gridItem}>
          <img
            src={require("../../../img/All topics.svg").default}
            alt="all-topics"
            style={{ width: "48px", display: "flex", margin: "auto" }}
          />{" "}
          View all topics from A-Z
        </Box>
      </Link>
    </Box>
  );
}

export default OtherTopics;
