import React from "react";
import { Button } from "@mui/material";

function ThankYouFeedbackCloseButton() {
  return (
    <div>
      <p className="pLabel" style={{ fontSize: "14px", marginBottom: "16px" }}>
        Thanks for your feedback!
      </p>
      <Button fullWidth sx={{ color: "white" }}>
        Close
      </Button>
    </div>
  );
}

export default ThankYouFeedbackCloseButton;
