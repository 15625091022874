import React, { useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import Headerbar from "./components/header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import * as workpalJS from "@workpal-build/workpaljs";
import LoadingOverlay from "./components/loading_overlay";
import ReactGA from "react-ga4";

const styles = {
  list: {
    width: "100%",
    padding: 0,
    bgcolor: "background.paper",
  },
  listitem: { paddingTop: "16px", paddingBottom: "15px" },
  divider: {
    borderColor: "#D0D0D0",
    borderBottomWidth: "1.2px",
  },
};

const AllRelatedArticlesPage = () => {
  const topicIssueList = useSelector((state) => state.data);
  const topicIndex = useSelector((state) => state.selectedOptions.topicIndex);
  const issueIndex = useSelector((state) => state.selectedOptions.issueIndex);
  const topic = useSelector((state) => state.selectedOptions.topic);
  const userapi = useSelector((state) => state.userapi);
  const topicId = topicIssueList[topicIndex].id;
  const issue = topicIssueList[topicIndex].issues[issueIndex].name;
  const issueId = topicIssueList[topicIndex].issues[issueIndex].id;
  const articleList = topicIssueList[topicIndex].issues[issueIndex].kb;
  //   alert(articleList[0].number);
  const [loadExternal, setLoadExternal] = useState(false);

  const clickHandler = (url) => {
    setLoadExternal(true);
    setTimeout(() => {
      setLoadExternal(false);
      workpalJS.openExternalBrowser(String(url));
    }, 3000);
  };

  return (
    <div>
      {loadExternal ? (
        <LoadingOverlay
          isOpen={loadExternal}
          message={
            <div style={{ marginBottom: "10px" }}>
              "Redirecting to external site"
            </div>
          }
        />
      ) : (
        <div>
          <Headerbar title={"Related articles"} />
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              backgroundColor: "#F8F8F8",
              paddingTop: "55px",
            }}
            noValidate
            autoComplete="off"
          >
            <List sx={styles.list} component="nav" aria-label="mailbox folders">
              {articleList.map((kbArticle, index) => (
                <div key={index}>
                  {kbArticle !== "Others" && (
                    <ListItem sx={styles.listitem} divider>
                      {kbArticle.kb_type === "kb_url" ? (
                        <Link
                          style={{ color: "#1D1D1D" }}
                          onClick={() => {
                            // alert(kbArticle.id);
                            //For Google Analytics
                            ReactGA.gtag("event", "Tap Article", {
                              articleId: kbArticle.id,
                              articleName: kbArticle.short_description,
                              issueName: issue,
                              issueId: issueId,
                              topicName: topic,
                              topicId: topicId,
                              sessionIdCustom: userapi.sessionID,
                              userId: userapi.userSysId,
                              agency: userapi.agency,
                              channel: "Mobile",
                              timestamp: Date(),
                            });

                            clickHandler(kbArticle.content);
                          }}
                        >
                          <ListItemText primary={kbArticle.short_description} />
                        </Link>
                      ) : (
                        <Link
                          to={`/relatedarticles/${index}`}
                          style={{ color: "#1D1D1D" }}
                          onClick={() => {
                            //For Google Analytics
                            ReactGA.event({
                              category: kbArticle.short_description, //issue
                              action: "Related Article", //topic
                              label: `Timestamp ${Date()}`,
                            });
                          }}
                        >
                          <ListItemText primary={kbArticle.short_description} />
                        </Link>
                      )}
                    </ListItem>
                  )}
                </div>
              ))}
            </List>
          </Box>
        </div>
      )}
    </div>
  );
};

export default AllRelatedArticlesPage;
