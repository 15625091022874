const dataReducer = (state = [], action) => {
  switch (action.type) {
    case "storeTopicIssueList":
      return action.payload;
    case "search":
      const searchArr = state;
      return searchArr.filter((arrItem) => {
        console.log(state);
        return arrItem.toLocaleLowerCase().includes(action.payload);
      });
    default:
      return state;
  }
};

export default dataReducer;
