import { combineReducers } from "redux";
import dataReducer from "./dataReduer";
import userReducer from "./userReducer";
import userApiReducer from "./userApiReducer";
import selectedOptionsReducer from "./selectedOptionsReducer";
import ticketReducer from "./ticketHistoryReducer";
import loadingReducer from "./loadingReducer";

const reducers = combineReducers({
  data: dataReducer,
  user: userReducer,
  userapi: userApiReducer,
  selectedOptions: selectedOptionsReducer,
  ticket: ticketReducer,
  loading: loadingReducer,
});

export default reducers;
