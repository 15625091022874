import React from "react";
import { Button, styled } from "@mui/material";

function NotResolvedButtons() {
  const BlueButton = styled(Button)(({ theme }) => ({
    borderRadius: "50px",
    backgroundColor: "#376EEE",
    width: "47%",

    textTransform: "none",
  }));
  const WhiteButton = styled(Button)(({ theme }) => ({
    borderRadius: "50px",
    border: "1px solid #376EEE",

    width: "47%",
    textTransform: "none",
    color: "#376EEE",
  }));
  return (
    <div>
      <p className="pLabel" style={{ fontSize: "14px", marginBottom: "16px" }}>
        Is your issue resolved?
      </p>
      <div style={{ justifyContent: "space-between", display: "flex" }}>
        <WhiteButton variant="outlined">No</WhiteButton>
        <BlueButton variant="contained">Yes</BlueButton>
      </div>
    </div>
  );
}

export default NotResolvedButtons;
