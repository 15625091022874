import React from "react";
import { Button, TextField } from "@mui/material";

function SubmitNotResolvedCommentsButton() {
  return (
    <div>
      <p className="pLabel" style={{ fontSize: "14px" }}>
        We’re sorry to hear that the issue is not resolved. Please let us know
        how we can better resolve the issue.
      </p>
      <TextField
        id="comments"
        label="Comments"
        type="tel"
        // value={}
        // onChange={}
        // required={}
        variant="standard"
        multiline
        rows={3}
        inputProps={{
          style: { fontSize: 14 },
        }}
        InputLabelProps={{
          style: { fontFamily: "Roboto", fontSize: "14px", fontWeight: 300 },
        }}
        sx={{ margin: "0px 16px", width: " -webkit-fill-available" }}
      />

      <Button fullWidth sx={{ marginTop: "16px", color: "white" }}>
        Submit
      </Button>
    </div>
  );
}

export default SubmitNotResolvedCommentsButton;
