const initialState = {
  jwt: "",
  email: "",
  device: {}, // keys are "manufacturer", "model", "OSVersion", "appVersion"
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "storeJWT":
      return { ...state, jwt: payload };
    case "storeUserDeviceInfo":
      return { ...state, device: payload };
    case "storeUserEmail":
      return { ...state, email: payload };

    default:
      return state;
  }
};

export default userReducer;
