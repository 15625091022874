import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import Headerbar from "./components/header";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import * as workpalJS from "@workpal-build/workpaljs";
import LoadingOverlay from "./components/loading_overlay";
import DOMPurify from "dompurify";
import MSAuthenticatorSteps from "./ms_authenticator/ms_authenticator_steps";
import ReactGA from "react-ga4";
import { color } from "framer-motion";

const styles = {
  cardbg: {
    backgroundColor: "#F8F8F8",
    boxShadow: "none",
  },
  black: {
    color: "#1D1D1D",
  },
  btn: {
    maxWidth: "100vw",
    boxShadow: "0 0 4px 1px #cbcaca69",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  articles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  articletitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 14,
  },
  links: {
    fontSize: "smaller",
    fontWeight: 500,
  },
  actionscard: {
    flexBasis: "32%",
    // maxWidth: "50%",
    minHeight: 120,
    display: "flex",
    alignItems: "flex-end",
  },
  cardcontent: { lineHeight: "16px", paddingBottom: "15px" },
  flex: {
    display: "flex",
  },
  articleicon: {
    marginRight: "5px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4D4D4D",
  },
  btnText: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize: "16px",
    color: "#4D4D4D",
    paddingBottom: "15px",
    textAlign: "center",
  },
  img: {
    maxWidth: "94vw",
    minWidth: "92vw",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  gridItem: {
    color: "#4D4D4D",
    height: "30vw",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    textAlign: "center",
    overflow: "hidden",
    fontSize: 12,
    fontWeight: 600,

    fontFamily: "Open Sans",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.30)",
  },
};

const IssueInfoPage = () => {
  const { issueid } = useParams();
  // console.log(issueid);
  const data = useSelector((state) => state.data);
  const topic = useSelector((state) => state.selectedOptions.topic);
  const topicIndex = useSelector((state) => state.selectedOptions.topicIndex);
  const issueIndex = useSelector((state) => state.selectedOptions.issueIndex);
  const phoneNum = useSelector((state) => state.userapi.phone);
  const userapi = useSelector((state) => state.userapi);

  const issueinfo = data[topicIndex].issues[issueIndex];
  const steps = issueinfo.steps?.replace(/\n/g, "<br>");
  const sanitizedHTML = DOMPurify.sanitize(steps);
  const articles = issueinfo.kb;
  const firstThreeArticles = articles.slice(0, 3);

  // if content starts withs <BODY>
  const hasBody = issueinfo.steps?.startsWith("<BODY>");
  const imginfo = hasBody
    ? JSON.parse(issueinfo.steps?.replace(/<BODY>/i, "").replace(/'/g, '"'))
    : "";

  const [loadExternal, setLoadExternal] = useState(false);

  return (
    <div>
      {loadExternal ? (
        <LoadingOverlay
          isOpen={loadExternal}
          message={
            <div style={{ marginBottom: "10px" }}>
              "Redirecting to external site"
            </div>
          }
        />
      ) : (
        <div>
          <Headerbar title={"Issue"} />
          {/* <h1>{i}</h1> */}
          <Box
            className="issue_info"
            sx={{ width: "100%", paddingTop: "55px" }}
            noValidate
            autoComplete="off"
          >
            <Container className="mt-3 mb-3">
              <Typography variant="h5" gutterBottom>
                <b>{issueinfo.name}</b>
              </Typography>
              <Chip
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "20px",
                  fontWeight: 600,
                  fontFamily: "Open Sans",
                }}
                label={topic}
              />

              <div>
                <Typography
                  className="mt-3"
                  variant="h6"
                  gutterBottom
                  style={styles.subtitle}
                >
                  Recommended Next Steps
                </Typography>

                {/* If there is no recommended steps and user need to report to external forms */}
                {issueinfo.steps_type === "actionButton" && (
                  <div>
                    <button
                      style={{
                        padding: 0,
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        setLoadExternal(true);
                        setTimeout(() => {
                          setLoadExternal(false);
                          workpalJS.openExternalBrowser(String(imginfo.link));
                        }, 3000);
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={imginfo.img_url}
                        alt="success"
                        style={styles.img}
                      />{" "}
                    </button>
                  </div>
                )}

                {/* If there is no recomended steps and user need to report to ITSM form */}
                {issueinfo.steps_type === "NA" && (
                  <div>
                    <Link
                      style={{
                        padding: 0,
                        backgroundColor: "white",
                      }}
                      to={`/report_incident_form/${issueinfo.topicid}/${issueid}`}
                    >
                      <img
                        src={require("../img/report-internal.svg").default}
                        alt="reportIT"
                        style={styles.img}
                      />
                    </Link>
                  </div>
                )}

                {/* If there is recommended steps */}
                {(issueinfo.steps_type === "steps" ||
                  issueinfo.steps_type === "steps_actionButton") && (
                  <Card sx={{ minWidth: 275 }} style={styles.cardbg}>
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHTML,
                        }}
                      ></div>
                    </CardContent>
                    <div
                      style={{
                        marginTop: "5px",
                        height: "10px",
                        backgroundColor: "white",
                      }}
                    ></div>
                  </Card>
                )}
              </div>

              {/* Hard code recommended steps for reset Microsoft Authenticator */}
              {issueinfo.steps_type === "MS Reset" && <MSAuthenticatorSteps />}

              {/* This section is for related articles */}
              {articles.length > 0 ? (
                <div>
                  <div style={styles.articles}>
                    <Typography
                      className="mt-3 mb-3"
                      variant="h6"
                      gutterBottom
                      style={styles.subtitle}
                    >
                      Related Articles
                    </Typography>

                    <Link
                      style={styles.links}
                      to={`/allrelatedarticles/${topic}/${issueIndex}`}
                    >
                      View All
                    </Link>
                  </div>
                  <Card sx={{ minWidth: 275 }} style={styles.cardbg}>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {firstThreeArticles.map(
                          (
                            article,
                            index // to show only first three articles
                          ) =>
                            article.kb_type === "kb_url" ? (
                              <Link
                                className="mt-2 mb-2"
                                style={{ ...styles.black, ...styles.flex }}
                                key={article.number}
                                onClick={() => {
                                  //For Google Analytics
                                  ReactGA.gtag("event", "Tap Article", {
                                    articleId: article.id,
                                    articleName: article.short_description,
                                    issueName: issueinfo.name,
                                    issueId: issueinfo.id,
                                    topicName: topic,
                                    topicId: data[topicIndex].id,
                                    sessionIdCustom: userapi.sessionID,
                                    userId: userapi.userSysId,
                                    agency: userapi.agency,
                                    channel: "Mobile",
                                    timestamp: Date(),
                                  });

                                  setLoadExternal(true);
                                  setTimeout(() => {
                                    setLoadExternal(false);
                                    workpalJS.openExternalBrowser(
                                      String(article.content)
                                    );
                                  }, 3000);
                                }}
                              >
                                <ArticleIcon
                                  style={styles.articleicon}
                                  color="primary"
                                />
                                <div
                                  className="article-title"
                                  style={styles.articletitle}
                                >
                                  {article.short_description}
                                </div>
                              </Link>
                            ) : (
                              <Link
                                className="mt-2 mb-2"
                                to={`/relatedarticles/${index}`}
                                style={{ ...styles.black, ...styles.flex }}
                                key={article.number}
                              >
                                <ArticleIcon
                                  style={styles.articleicon}
                                  color="primary"
                                />
                                <div
                                  className="article-title"
                                  style={styles.articletitle}
                                >
                                  {article.short_description}
                                </div>
                              </Link>
                            )
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              ) : (
                ""
              )}

              {/* This section is related to contact IT support even there are recommended steps given to user */}
              {(issueinfo.steps_type == "steps" ||
                issueinfo.steps_type === "steps_actionButton" ||
                issueinfo.steps_type === "NA" ||
                issueinfo.steps_type === "MS Reset") && (
                <div>
                  <Typography
                    className="mt-3 mb-3"
                    variant="h6"
                    gutterBottom
                    style={styles.subtitle}
                  >
                    Contact IT Support
                  </Typography>

                  {issueinfo.steps_type === "MS Reset" && (
                    <div>
                      <Typography className="mt-3 mb-3">
                        Still encountered problems after attempting step 1?
                        Report the issue instead.
                      </Typography>
                    </div>
                  )}

                  <Grid container spacing={1.5} rowSpacing={1}>
                    {(issueinfo.steps_type == "steps" ||
                      issueinfo.steps_type === "MS Reset") && (
                      <Grid item xs={4}>
                        <Link
                          to={`/report_incident_form/${issueinfo.topicid}/${issueid}`}
                        >
                          <Box sx={styles.gridItem}>
                            <img
                              src={
                                require("../img/Contact-Report-issue.svg")
                                  .default
                              }
                              alt="Report IT Issue"
                              style={{
                                width: "48px",
                                display: "flex",
                                margin: "auto",
                              }}
                            />{" "}
                            Report IT Issue
                          </Box>
                        </Link>
                      </Grid>
                    )}

                    {issueinfo.steps_type == "steps_actionButton" && (
                      <Grid item xs={4}>
                        <Link
                          to={`/report_incident_form/${issueinfo.topicid}/${issueid}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setLoadExternal(true);
                            setTimeout(() => {
                              workpalJS.openExternalBrowser(
                                String(issueinfo.steps_url)
                              );
                              setLoadExternal(false);
                            }, 3000);
                          }}
                        >
                          <Box sx={styles.gridItem}>
                            <img
                              src={
                                require("../img/Contact-Report-issue.svg")
                                  .default
                              }
                              alt="Contact-Report-issue"
                              style={{
                                width: "48px",
                                display: "flex",
                                margin: "auto",
                              }}
                            />
                            Report IT issue to product team
                          </Box>
                        </Link>
                      </Grid>
                    )}

                    {
                      <Grid item xs={4}>
                        <Link
                          onClick={() => {
                            //GA report
                            ReactGA.gtag("event", "Call Service Desk", {
                              issueName: issueinfo.name,
                              issueId: issueinfo.id,
                              topicName: topic,
                              topicId: data[topicIndex].id,
                              sessionIdCustom: userapi.sessionID,
                              userId: userapi.userSysId,
                              agency: userapi.agency,
                              channel: "Mobile",
                              timestamp: Date(),
                            });
                          }}
                          to={`tel:${phoneNum}`}
                        >
                          {" "}
                          <Box sx={styles.gridItem}>
                            <img
                              src={require("../img/contact-call.svg").default}
                              alt="Call IT Support"
                              style={{
                                width: "48px",
                                display: "flex",
                                margin: "auto",
                              }}
                            />{" "}
                            Call IT Support
                          </Box>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                </div>
              )}
            </Container>
          </Box>
        </div>
      )}
    </div>
  );
};

export default IssueInfoPage;
