// ErrorDialog.js
import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const ErrorDialog = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img
          style={{ padding: "20px", margin: "auto", display: "block" }}
          src={require("../../img/ErrorGraphics.png")}
          alt="Error Graphic"
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ padding: "2px 15px", textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
