import React from "react";
import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { storeSelectedTopic } from "../../../state/action_creators";
import ReactGA from "react-ga4";

function TopTopicsGrids() {
  const topicIssueList = useSelector((state) => state.data);
  const sth = topicIssueList;

  const sortedTopics = sth.sort((a, b) => b.incident_count - a.incident_count);

  const topTopicList = sortedTopics.slice(0, 6);

  const userapi = useSelector((state) => state.userapi);
  const dispatch = useDispatch();

  const clickTopicHandler = (topic, topicId) => {
    dispatch(storeSelectedTopic(topic));
    // dispatch(storeSelectedTopicIndex(index));

    // alert(topicId);

    //Google Analytics
    ReactGA.gtag("event", "Tap Topic", {
      topicName: topic,
      topicId: topicId,
      sessionIdCustom: userapi.sessionID,
      userId: userapi.userSysId,
      agency: userapi.agency,
      channel: "Mobile",
      timestamp: Date(),
    });
  };

  // MUI styling
  const styles = {
    gridItem: {
      height: "30vw",
      // background: "#F8F8F8",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      // paddingLeft: "8px",
      // paddingBottom: "8px",
      padding: "8px",
      textAlign: "center",
      overflow: "hidden",
      fontSize: 12,
      fontWeight: 600,

      fontFamily: "Open Sans",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.30)",
    },
    title: {
      fontFamily: "Open Sans",
      color: "#4D4D4D",
      fontWeight: 700,
      fontSize: 16,
      display: "flex",
    },
    page: {
      padding: "16px",
    },
    link: {
      color: "#4D4D4D",
    },
  };
  return (
    <motion.div>
      <Box style={styles.page}>
        <h1 style={styles.title}>Top Topics</h1>
        {
          <Grid container spacing={1.5} rowSpacing={1}>
            {topTopicList.map((eachTopic, i) => (
              <Grid item xs={4} key={i}>
                <Link
                  to={`/issue_list/${eachTopic.topic}`}
                  style={styles.link}
                  onClick={() => {
                    clickTopicHandler(eachTopic.topic, eachTopic.id);
                  }}
                >
                  <Box sx={styles.gridItem}>
                    <img
                      src={`${eachTopic.icon}`}
                      alt={eachTopic.topic}
                      style={{ width: "48px", display: "flex", margin: "auto" }}
                    />

                    {eachTopic.topic}
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        }
      </Box>
    </motion.div>
  );
}

export default TopTopicsGrids;
