import React, { useState, useEffect } from "react";
import { Box, Container, InputAdornment, TextField } from "@mui/material";
import { ArrowForwardOutlined } from "@mui/icons-material";
// import Headerbar from "./header";
import moment from "moment";
import uuid from "react-native-uuid";
import axios from "axios";

const headerHeight = "60px";

const styles = {
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${headerHeight})`,
  },
  messageContainer: {
    overflowY: "scroll",
    flex: 1,
    padding: "0.5rem",
    minHeight: 0,
  },
  messageWrapper: (role) => ({
    alignSelf: role === "user" ? "flex-end" : "flex-start",
    backgroundColor: role === "user" ? "#EDF8FF" : "#F8F8F8",
    color: role === "user" ? "#1D1D1D" : "#1D1D1D",
    padding: "1rem",
    margin: "1rem 0.5rem",
    borderRadius: "0.5rem",
    maxWidth: "90%",
    wordBreak: "break-word",
    marginLeft: role === "user" ? "auto" : "initial",
    borderBottomLeftRadius: role === "user" ? "0.5rem" : "0",
    borderBottomRightRadius: role === "user" ? "0" : "0.5rem",
  }),
  messageHeader: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontSize: "12px",
    fontWeight: 600,
  },
  inputContainer: {
    backgroundColor: "#fff",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
  sendButton: {
    borderRadius: "50px",
    fontSize: "1.5rem",
    border: "1.7px solid #376EEE",
  },
  msginfo: {
    textAlign: "center",
    color: "#AAAAAA",
    fontSize: "12px",
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const ChatPage = () => {
  // localStorage.setItem("chatMessages", null);
  // const storedMessages = localStorage.getItem("chatMessages"); // BQ: Won't this always be null since you set it as such above???
  // const initialMessages = storedMessages
  //   ? JSON.parse(storedMessages)
  //   : messages;

  // const defaultMessages = [
  //   {
  //     id: "1",
  //     type: "info",
  //     role: "system",
  //     username: "system",
  //     datetime: "01 Mar 2023 6:30 PM",
  //     message:
  //       "James Lee (IT Support) has set the incident state to ‘In Progress’",
  //   },
  //   {
  //     id: "2",
  //     type: "chat",
  //     role: "user",
  //     username: "Riiko Wang",
  //     datetime: "02 Mar 2023 9:47 AM",
  //     message: "I'm having trouble with my laptop",
  //   },
  //   {
  //     id: "3",
  //     type: "chat",
  //     role: "support",
  //     username: "James Lee (IT Support)",
  //     datetime: "02 Mar 2023 9:51 AM",
  //     message: "I'm sorry to hear that. Can you provide more details?",
  //   },
  //   {
  //     id: "4",
  //     type: "info",
  //     role: "system",
  //     username: "system",
  //     datetime: "02 Mar 2023 10:00 PM",
  //     message:
  //       "James Lee (IT Support) has set the incident state to ‘Pending response’",
  //   },
  // ];

  // const initialMessages = storedMessages
  //   ? JSON.parse(storedMessages)
  //   : defaultMessages;
  /**
   * Edit from Ben
   * Above code was original (line 66-68). I think you intended for it to be blank since there is no var called messages defined above original.
   * Since storedMessages will always be null (see above comments), you set default messages to initialMessages. Then can remove the OR in the useState init values.
   *
   */

  // const [messages, setMessages] = useState(initialMessages); // Removed defaultMessages as explained above.

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://u2tk6e81pl.execute-api.ap-southeast-1.amazonaws.com/prod/chat"
        );
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // remove user object due to code review

  const [newMessage, setNewMessage] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage(newMessage);
    }
  };

  // add new message to array & update array in localStorage
  const handleSendMessage = (message) => {
    if (message === "") return;
    const newMessageData = {
      id: uuid.v4(),
      type: "chat",
      role: "user",
      username: "john",
      datetime: moment().format("DD MMM YYYY h:mm A"),
      message,
    };
    const updatedMessages = [...messages, newMessageData];
    setMessages(updatedMessages);
    setNewMessage("");
    localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
  };

  return (
    <>
      {/* <Headerbar title={"Activity"} /> */}
      <Box sx={styles.chatContainer}>
        <Container sx={styles.messageContainer}>
          {messages.map((message, index) => (
            <div key={message.id}>
              {message.type === "info" && (
                <div style={styles.msginfo}>{message.message}</div>
              )}
              {message.type === "chat" && (
                <div style={styles.messageWrapper(message.role)}>
                  <div style={styles.messageHeader}>
                    <span style={{ color: "#757575" }}>{message.username}</span>
                    <span style={{ color: "#aaaaaa" }}>{message.datetime}</span>
                  </div>
                  <p style={{ margin: 0, fontSize: "14px", paddingTop: "5px" }}>
                    {message.message}
                  </p>
                </div>
              )}
            </div>
          ))}
        </Container>
        <Container sx={styles.inputContainer}>
          <TextField
            placeholder="Message"
            value={newMessage}
            onChange={(event) => setNewMessage(event.target.value)}
            variant="standard"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowForwardOutlined
                    color="primary"
                    style={styles.sendButton}
                    aria-label="send message"
                    onClick={() => handleSendMessage(newMessage)}
                    edge="end"
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          />
        </Container>
      </Box>
    </>
  );
};

export default ChatPage;
