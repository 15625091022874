import React from "react";
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

const LoadingOverlay = ({ isOpen, message = null }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
    >
      <Card variant="outlined" sx={{ width: "90%", borderRadius: "10px" }}>
        <CardContent
          sx={{
            textAlign: "center",
            paddingLeft: "0px",
            paddingRight: "0px",
            marginTop: "15px",
            padding: 0,

            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          {!message && (
            <CircularProgress sx={{ marginBottom: "10px" }} color="inherit" />
          )}

          {message && (
            <Typography variant="h6" color="inherit" mt={-0.5}>
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Backdrop>
  );
};

export default LoadingOverlay;
