import React from "react";
import { Container, Typography, TextField } from "@mui/material";

const MyDetails = ({ formData }) => {
  return (
    <Container className="mt-3 mb-3">
      <Typography variant="h6" gutterBottom>
        My Details
      </Typography>

      <TextField
        id="name"
        label="Name"
        value={formData.name}
        type="text"
        InputProps={{ readOnly: true, disableUnderline: true }}
        variant="standard"
        fullWidth
        margin="dense"
      />

      <TextField
        id="email"
        label="Work Email Address"
        type="email"
        value={formData.email}
        InputProps={{ readOnly: true, disableUnderline: true }}
        variant="standard"
        fullWidth
        margin="dense"
      />

      <TextField
        id="agency"
        label="Agency"
        value={formData.agency}
        type="text"
        InputProps={{ readOnly: true, disableUnderline: true }}
        variant="standard"
        fullWidth
        margin="dense"
      />
    </Container>
  );
};

export default MyDetails;
