import React from "react";
import TopTopicsGrids from "./top_topics_grids";
import OtherTopics from "./other_topics";

function Topics() {
  return (
    <div>
      <TopTopicsGrids />
      <OtherTopics />
    </div>
  );
}

export default Topics;
