import React from "react";
import NotResolvedButtons from "./not_resolved_button.component";
import RatingComponent from "./rating.component";
import SubmitNotResolvedCommentsButton from "./submit_not_resolved_comments";
import ThankYouFeedbackCloseButton from "./thank_you_feedback_close_button.component";

function ResolutionDetails({ resolutionDetails, status }) {
  const { person, resolutionNotes, resolutionConfirmation, rating, comments } =
    resolutionDetails;
  const styles = {
    subtitle: {
      color: "#4D4D4D",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      letterSpacing: "0.15px",
      marginTop: "16px",
      marginBottom: "16px",
    },
  };
  return (
    <div style={{ padding: "16px" }}>
      <h6 style={styles.subtitle}>Resolution Details</h6>

      <p className="detailContent">
        We note that <span style={{ fontWeight: "bold" }}> {person} </span>
        has resolved the issues for you
      </p>

      <p className="pLabel">Resolution notes</p>
      <h6 className="detailContent">{resolutionNotes}</h6>
      <h6 style={styles.subtitle}>Resolution Confirmation</h6>

      {resolutionConfirmation ? (
        rating ? (
          <ThankYouFeedbackCloseButton /> // The issue is resolved and user has given a rating
        ) : (
          <RatingComponent /> // The issue is resolved but user has yet to give a rating
        )
      ) : status === "Pending confirmation" ? (
        <NotResolvedButtons /> // The issue is being worked on but pending on user's confirmation that it has been resolved
      ) : comments ? (
        <p className="pLabel" style={{ fontSize: "14px" }}>
          Thank you for your comments. We’ll inform the IT Support team and work
          on your issue.
        </p>
      ) : (
        <SubmitNotResolvedCommentsButton /> // The issue is being worked on but user responded as not yet resolved
      )}
    </div>
  );
}

export default ResolutionDetails;
