import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeSelectedTopic } from "../../../state/action_creators";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

function TopicListTile({ topic, incform }) {
  const styles = {
    fontStyle: {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      textDecoration: "none",
      lineHeight: "20px",
      paddingLeft: "16px",
    },
  };
  const userapi = useSelector((state) => state.userapi);
  const dispatch = useDispatch();

  return (
    <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
      <Link
        to={`/issue_list/${topic.topic}`}
        onClick={() => {
          dispatch(storeSelectedTopic(topic.topic));
          // alert(topic.id);
          //Google Analytics

          ReactGA.gtag("event", "Tap Topic", {
            topicName: topic.topic,
            topicId: topic.id,
            sessionIdCustom: userapi.sessionID,
            userId: userapi.userSysId,
            agency: userapi.agency,
            channel: "Mobile",
            timestamp: Date(),
          });
          // ReactGA.event({
          //   category: "Go to list of related issues", //issue
          //   action: `Clicked on ${topic.topic}`, //topic (in ga is call event)
          //   label: `Timestamp ${Date()}`,
          // });
        }}
      >
        <ListItem sx={{ padding: "16px" }}>
          <ListItemAvatar>
            <img
              // src={require("../../../img/Asset Management.png")}
              src={`${topic.icon}`}
              alt={topic.topic}
              style={{ width: "48px", display: "flex", margin: "auto" }}
            />
            {/* <Avatar sx={{ bgcolor: "#FDE7D4" }}> </Avatar> */}
          </ListItemAvatar>
          <ListItemText
            primary={topic.topic}
            primaryTypographyProps={styles.fontStyle}
          />
        </ListItem>

        <Divider
          style={{
            background: "#D0D0D0",
            borderBottomWidth: "1px",
            borderColor: "rgba(0, 0, 0, 0.35)",
          }}
        />
      </Link>
    </List>
  );
}

export default TopicListTile;
